<template>
  <div>
    <page-title />
    <b-alert
      :show="!!(loadError || saveError)"
      variant="danger"
      v-text="loadError || saveError"
    />
    <b-alert
      :show="!!saveSuccess"
      variant="success"
    >
      Saved
    </b-alert>
    <template v-if="!loading">
      <b-card v-if="!loadError">
        <b-form @submit.prevent="save()">
          <b-form-group
            v-for="(value, param) in config"
            :key="param"
            :label="getParamLabel(param)"
            :label-for="`config-${param}`"
          >
            <template v-if="isNumber(param)">
              <b-form-input
                :id="`config-${param}`"
                v-model="config[param]"
              />
            </template>
            <template v-else>
              <b-form-checkbox
                :id="`config-${param}`"
                v-model="config[param]"
              />
            </template>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            :disabled="saving"
          >
            <b-spinner
              v-if="saving"
              small
            />
            Update
          </b-button>
        </b-form>
      </b-card>
    </template>
    <b-spinner v-else />
  </div>
</template>

<script>
import ApiService from '@/ApiService'
import { getRequestErrorText } from '@/utils'
import PageTitle from '@/components/PageTitle'

const paramsLabels = {
  answerPriceCents: {
    title: 'Бонус за верный ответ',
    type: 'number'
  },
  fineCents: {
    title: 'Штраф за неверный ответ',
    type: 'number'
  },
  restartedCardFineCents: {
    title: 'Штраф за неверный ответ по перезапущенной карточке',
    type: 'number'
  },
  quorumWithTimer: {
    title: 'Кворум по таймеру, голосов',
    type: 'number'
  },
  closingQuorum: {
    title: 'Закрывающий кворум, голосов',
    type: 'number'
  },
  firstCorrectAnswerBonusCents: {
    title: 'Бонус за первый верный ответ',
    type: 'number'
  },
  bonusForCorrectIfMoreThanNCents: {
    title: 'Бонус если в карточке больше N верных ответов',
    type: 'number'
  },
  correctAnswersNumBonusThreshold: {
    title: 'Бонус если в карточке больше N верных ответов. N верных',
    type: 'number'
  },
  bonusForEachNCorrectCents: {
    title: 'Бонус за каждые X верных из Y',
    type: 'number'
  },
  nAnswersThreshold: {
    title: 'Бонус за каждые X верных из Y. Y',
    type: 'number'
  },
  maxErrorsInEachNAnswers: {
    title: 'Бонус за каждые X верных из Y. Допустимое кол-во ошибок',
    type: 'number'
  },
  presenceBonus: {
    title: 'Бонус присутствия',
    type: 'number'
  },
  presenceBonusThreshold: {
    title: 'Бонус присутствия. Считать каждые N',
    type: 'number'
  },
  presenceBonusVotesRequired: {
    title: 'Бонус присутвия. Начислять если юзер голосовал за Y из N',
    type: 'number'
  },
  timerInSeconds: {
    title: 'Время в секундах до закрытия карточки после набора мин. кворума',
    type: 'number'
  },
  bombFineCents: {
    title: 'Штраф за бомбу',
    type: 'number'
  },
  cardTitleIsHidden: {
    title: 'Скрыть названия карточек',
    type: 'bool'
  },
  hangingCardThresholdMinutes: {
    title: 'Блокировать, если карточка висит больше N минут. 0 = отключено',
    type: 'number'
  }
}

export default {
  name: 'Config',
  components: { PageTitle },
  data () {
    return {
      config: {},
      loading: false,
      loadError: '',
      saving: false,
      saveSuccess: null,
      saveError: ''
    }
  },
  mounted () {
    this.loading = true

    ApiService.get('/config')
      .then(response => {
        this.config = response.data
      })
      .catch(error => {
        this.loadError = getRequestErrorText(error, 'Config load failed. Please, try refresh page.')
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    save () {
      this.saving = true
      this.saveSuccess = null
      this.saveError = ''

      ApiService.post('/config', this.config)
        .then(() => {
          this.saveSuccess = true
        })
        .catch(error => {
          this.saveError = getRequestErrorText(error)
        })
        .finally(() => {
          this.saving = false
        })
    },
    getParamLabel (param) {
      return Object.keys(paramsLabels).includes(param) ? paramsLabels[param].title : param
    },
    isNumber (param) {
      return Object.keys(paramsLabels).includes(param) ? paramsLabels[param].type === 'number' : false
    }
  }
}
</script>
